var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"body"}},[_vm._m(0),_c('div',{attrs:{"id":"wx_tit"}},[_vm._m(1),_vm._m(2),_c('div',{attrs:{"id":"wx_tit_main"}},[_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"dianpu1"}}),_c('p',[_vm._v("单店管理")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"dianpu"}}),_c('p',[_vm._v("连锁管理")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"xiaoshoudingdan"}}),_c('p',[_vm._v("销售数据")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"fuwu"}}),_c('p',[_vm._v("会员管理")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"icon-p_dangqiankucun"}}),_c('p',[_vm._v("库存查询")])],1)]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]),_c('div',{attrs:{"id":"wshopbox"}},[_c('p',{attrs:{"id":"on_wx"}},[_vm._v("限时免费体验掌上管家")]),_c('p',{attrs:{"id":"tw_wx"}},[_vm._v("你手机里的移动店铺，在线掌握店铺一手数据")]),_c('p',{attrs:{"id":"tw_wx"}},[_vm._v("会员管理 | 销售管理 | 营销管理 | 库存管理")]),_c('p',{attrs:{"id":"tw_wx"}},[_vm._v("￥0")]),_c('button',{attrs:{"type":"button"},on:{"click":_vm.tyfun}},[_vm._v("免费体验")])]),_c('footers')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wx-banner"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/zsgj.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wx_tit_header"}},[_c('p',[_vm._v("店铺管理，尽在掌中")]),_c('span',[_vm._v("大总管掌上管家让您把店铺的销售数据，会员信息，库存明细尽在掌中，让您实现把店铺管理放在口袋中")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wx-img"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/gj01.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"600PX","padding-top":"10%"},attrs:{"id":"WX-shop"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/gjmb.jpg"}}),_c('div',{attrs:{"id":"WX-shop_NAME"}},[_c('p',{attrs:{"id":"Ti"}},[_vm._v("会员管理")]),_c('span',[_vm._v(" 线下门店会员实时同步手机移动端，让您可通过掌上管家APP实时查看会员信息，会员实时销费明细等会员的相关数据")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"WX-shop"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/gjyg.jpg"}}),_c('div',{attrs:{"id":"WX-shop_NAME"}},[_c('p',{attrs:{"id":"Ti"}},[_vm._v("员工提成管理")]),_c('span',[_vm._v("员工提成管理，通过手机端可实时统计查看店里每个业务员的工作情况，提成明细结合后台强大的提成设置，可在手机端完美查看每个员工的业绩状况提成明细")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"600PX","margin-top":"-140px"},attrs:{"id":"WX-shop"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/gjkc.jpg"}}),_c('div',{attrs:{"id":"WX-shop_NAME"}},[_c('p',{attrs:{"id":"Ti"}},[_vm._v("库存管理")]),_c('span',[_vm._v("可让您实时了解仓库中商品数量的明细数据，以及提醒您哪些商品过了预警线，提示您需要补货")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"paybgc"}},[_c('i',{staticClass:"el-icon-circle-check"}),_c('p',[_vm._v("可实时对接店铺收银系统")]),_c('span',[_vm._v("让您把店铺的经营放在掌中实时了解店铺的销售、采购，会员、营销等一些关键性的数据全面提高您的管理效率。")])])
}]

export { render, staticRenderFns }